// Style for Animations

$m: animate;

.#{$m} {
    @include transition(0.4s);
    backface-visibility: hidden;

    &.fadeRight {
        opacity: 0;
        @include translateX(50%);
    }

    &.fadeLeft {
        opacity: 0;
        @include translateX(-50%);
    }

    &.fadeUp {
        opacity: 0;
        @include translateY(50%);
    }

    &.fadeDown {
        opacity: 0;
        @include translateY(-50%);
    }

    &.fadeIn {
        opacity: 0;
    }

    // &.bg-graphic--left {
    //     @include translateX(-50%);
    // }

    // &.bg-graphic--right {
    //     @include translateX(50%);
    // }

    &.animated {
        &.fadeRight, &.fadeLeft {
            opacity: 1;
            @include translateX(0%);
        }

        &.fadeUp, &.fadeDown {
            opacity: 1;
            @include translateY(0%);
        }

        &.fadeIn {
            opacity: 1;
        }

        // &.bg-graphic--left {
        //     @include translateX(-10%);
        // }

        // &.bg-graphic--right {
        //     @include translateX(10%);
        // }
    }

    &.delay-1 {
        transition-delay: 0.2s;
    }

    &.delay-2 {
        transition-delay: 0.4s;
    }

    &.delay-3 {
        transition-delay: 0.6s;
    }

    &.delay-4 {
        transition-delay: 0.8s;
    }

    &.delay-5 {
        transition-delay: 1s;
    }

    body.no-csstransitions & {
        opacity: 1;
    }
}