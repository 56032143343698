// Style for Footer

$m: footer;

#{$m} {
    h5 {
        @include font-size(36);
        font-weight: $bold;
        font-style: italic;
        color: $color_01;
        margin: 40px 0 40px 20px;
    }
}