//**************************************************
// DEFAULT
//**************************************************
body {
    color: $color_01;
}


//**************************************************
// LINKS
//**************************************************
a {
    color: $color_01;
}
a:link, a:active, a:visited, a:hover{
    text-decoration: none;
    outline: 0;
}
a:active, a:visited {
    outline: 0;
}


