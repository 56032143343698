// Style for Header

$m: header;

.#{$m} {
    position: relative;
    width: 100%;
    height: 0;
    min-height: 300px;
    background: transparent url('../img/header-bg.jpg') no-repeat;
    background-size: cover;
    background-position: center center;

    @include min-screen($sm) {
        width: 100%;
        box-sizing: border-box;
        height: auto;
    }

    &--home {
        background: transparent url('../img/header-bg-home.jpg') no-repeat;
        padding-bottom: 35%;
        background-position: center center;
        min-height: 200px;

        @include min-screen-height(700px) {
            padding-bottom: 41.6666667%;
        }

        @include min-screen($sm) {
            box-sizing: border-box;
            background-size: 100% auto;
            background-position: top center;
        }
    }

    &__text {
        position: absolute;
        z-index: 10;
        width: 100%;
        top: 40%;
        left: 0;
        right: 0;
        @include translateY(-43%);
        @extend %noselect;
        @extend %noclick;

        &--inner {
            position: relative;
            z-index: 5;
        }
    }

    &__site_title {
        @include font-size(36);
        @include line-height(36);
        color: $color_01;
        text-align: center;
        margin: 0;
        padding: 0 30px;
        position: relative;

        span {
            color: $color_04;
        }

        @include min-screen($md) {
            padding: 0;
            @include font-size(48);
            @include line-height(48);
        }

        @include min-screen($lg) {
            @include font-size(96);
            @include line-height(96);
        }


        // wine and glass graphic
        &:before {
            content: '';
            display: block;
            background: transparent url('../img/graphic-14.png') left top no-repeat;
            width: 100px;
            height: 190px;
            background-size: 100% auto;
            position: absolute;
            top: -75px;
            left: -20px;
            opacity: 0.3;
            @extend %noclick;

            @include min-screen($sm) {
                width: 80px;
                height: 150px;
                top: -30px;
                left: 10px;
                opacity: 1;
            }

            @include min-screen($md) {
                width: 110px;
                height: 210px;
                top: -40px;
                left: 40px;
            }

            @include min-screen($lg) {
                width: 180px;
                height: 350px;
                top: -60px;
                left: -120px;
            }
        }

        // grape graphic
        &:after {
            content: '';
            display: block;
            background: transparent url('../img/graphic-15.png') left top no-repeat;
            width: 100px;
            height: 180px;
            background-size: 100% auto;
            position: absolute;
            top: 30%;
            right: -20px;
            opacity: 0.3;
            @extend %noclick;

            @include min-screen($sm) {
                width: 100px;
                height: 190px;
                top: 90%;
                right: 10px;
                opacity: 1;
            }

            @include min-screen($md) {
                width: 130px;
                height: 106px;
                top: 100%;
                right: 60px;
            }

            @include min-screen($lg) {
                width: 180px;
                height: 150px;
                top: 90%;
                right: -70px;
            }
        }
    }

    &__quote {
        @include font-size(16);
        @include line-height(18);
        text-align: center;
        padding: 0 30px;

        @include min-screen($md) {
            padding: 0;
            @include font-size(20);
            @include line-height(24);
        }

        @include min-screen($lg) {
            @include font-size(24);
            @include line-height(30);
        }
    }



    &__page_title {
        @include font-size(36);
        @include line-height(36);
        color: $color_01;
        text-align: center;
        margin: 0;
        padding: 40px 20px;
        position: relative;

        @include min-screen($md) {
            padding: 80px 20px;
            @include font-size(48);
            @include line-height(48);
        }
    }

    // Clouds
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 330px;
        background: transparent url('../img/clouds.png') no-repeat bottom center;
        background-size: 200% auto;
        z-index: 0;

        @include min-screen($sm) {
            background-size: 100% auto;
        }
    }
    
    &__image {
        @include border-radius(8px);
        @include box-shadow(0px 2px 4px 0 rgba(0, 0, 0, 0.3));
        width: 90%;
        margin: 0 5%;

        @include min-screen($lg) {
            width: 100%;
            margin: 0;
        }
    }

    &__back, &__download {
        display: block;
        position: fixed;
        z-index: 500;
        padding: 0;
        top: 10px;
        left: 10px;
        @include border-radius(100px);
        background: #FFF url('../img/arrow@3x.png') no-repeat;
        background-position: center left 14px;
        background-size: 13px auto;
        @include font-size(16);
        @include transition(all 0.2s);
        @include box-shadow(0px 2px 4px rgba(0,0,0,0.2));
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        color: rgba(0,0,0,0);

        &:hover {
            background-position: center left 10px;
            @include box-shadow(0px 2px 10px rgba(0,0,0,0.2));
        }


        @include min-screen($lg) {
            top: 40px;
            left: 40px;
            padding: 7px 15px 7px 35px;
            color: #000;
            width: 120px;
        }

    }

    &__download {
        left: auto;
        right: 10px;
        background: #FFF url('../img/download@3x.png') no-repeat;
        background-position: top 11px left 14px;
        background-size: 13px auto;

        &:hover {
            background-position: top 15px left 14px;
        }

        @include min-screen($lg) {
            top: 40px;
            left: auto;
            right: 40px;
        }
    }
}