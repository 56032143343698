@media print {
    .header__back, .header__download {
        display: none !important;
    }
    .header {
        height: inherit !important;
        min-height: auto !important;
        background: none !important;
    }
    footer {
        display: none !important;
    }
    .header__image {
        display: none !important;
    }
    .header:after {
        background-size: 100% auto !important;
    }
    .content p {
        margin: 30px 60px !important;

        &.quote {
            background: none !important;
            min-height: 0 !important;
            padding: 30px 0 !important;
        }
    }

    .animate.fadeIn {
        opacity: 1 !important;
    }

    .content ol {
        margin: 30px 60px !important;
    }

    .content .bg-graphic {
        page-break-before: always;
    }

    .content p abbr:after {
      content: ' (' attr(title) ')';
    }

    .content table {
        page-break-inside: avoid;
        display: block;
        border: none;

        * {
            border: none;
        }

        tbody {
            display: block;
            width: 100%;
        }

        tr, td {
            display: block;
            text-align: center;
            width: 100%;
        }

        td p {
            margin: 10px 0 !important;
        }
    }
}