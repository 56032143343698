// Style for Menu

$m: navigation;

.#{$m} {
    position: relative;

    @include min-screen($sm) {
        margin-top: -30px;
    }

    @include min-screen($lg) {
        margin-top: -100px;
    }


    @include min-screen($sm) {
        & .gr-12:nth-child(1) {
            text-align: right;
        }
        & .gr-12:nth-child(2) {
            text-align: left;
        }
        & .gr-12:nth-child(3) {
            text-align: right;
        }
        & .gr-12:nth-child(4) {
            text-align: left;
        }
    }

    &__item {
        display: block;
        position: relative;
        width: 100%;
        max-width: 250px;
        height: 0;
        padding-bottom: 250px;
        display: table;
        margin: 0 auto;
        box-sizing: border-box;
        background: #FFF;
        @include border-radius(8px);
        @include box-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
        @include transition(all 0.2s);
        backface-visibility: hidden;
        background-position: top center;
        background-size: 100% auto;
        margin-bottom: 20px;

        &:hover {
            @include translateY(-10px);
            @include box-shadow(0px 20px 15px rgba(0, 0, 0, 0.1));
        }

        @for $i from 1 through 4 {
          &--#{$i} {
            background-image: url('../img/menu-#{$i}.jpg');
          }
        }

        @include min-screen($sm) {
            display: inline-block;
        }

        @include min-screen($lg) {
            max-width: 100%;
            padding-bottom: 100%;
            display: table;
        }
    }

    &__link {
        @include font-size(24);
        text-align: center;
        position: absolute;
        bottom: 0;
        background: #FFF;
        width: 100%;
        margin: 0;
        padding-bottom: 28px;
        @extend %noselect;

        &:before {
            content: '';
            position: absolute;
            background: transparent url('../img/clouds-menu.png') bottom center no-repeat;
            width: 100%;
            height: 0;
            top: 0;
            left: 0;
            @include translateY(-99%);
            padding-bottom: 25%;
            background-size: 100% auto;
        }
    }

    &--footer {
        margin-top: 0;
    }

}