//---------------------------------------------------------------
// GRID & LAYOUT SETTINGS
//---------------------------------------------------------------

// Grid specific:
$grid: 12;
$gutter: 20px;
$gutter_sm: 20px;
$gutter_md: 20px;
$gutter_lg: 20px;
$gutter_xlg: 20px;
$gutter_xxlg: 20px;


// Grid & Layout breakpoints:
$container-max-width: 960px;

$xs: 479px;
$sm: 480px;
$md: 768px;
$lg: 1024px;
$xlg: 1200px;
$xxlg: 1600px;


// Širina ekrana na kojoj se preslaguju column_content i column_sidebar
$column_break: 768px;


//---------------------------------------------------------------
// FONTS
//---------------------------------------------------------------
$font1: 'Playfair Display', serif;
$font2: 'Source Serif Pro', serif;


$base-fontSize: 62.5%;
$base-lineHeight: 1.5;


// Font weights
// $thin: 100;
// $ultra_light: 200;
$light: 300;
$normal: 400;
// $medium: 500;
$semi_bold: 600;
$bold: 700;
// $extra_bold: 800;
$black: 900;




//---------------------------------------------------------------
// COLORS
//---------------------------------------------------------------
//font colors
$color_01: #4f2e2e; // Headings
$color_02: #3d2223; // Body text
$color_03: #bc9749; // Quotes
$color_04: #8f0f21; // Headings red




//Social networks colors
$color_facebook: #3b5998;
$color_twitter: #00b6f1;
$color_youtube: #b31217;
$color_instagram: #c32aa3;
$color_instagram: #c32aa3;
$color_pinterest: #cb2027;
$color_googleplus: #df4a32;
$color_googleplus: #df4a32;
$color_linkedin: #007bb6;
$color_vimeo: #45bbff;