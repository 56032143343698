//---------------------------------------------------------------
// Iconset (font icons)
//---------------------------------------------------------------
//@import "https://i.icomoon.io/public/temp/f013016493/BestOfIsland/style.css";

//---------------------------------------------------------------
// Icon template
//---------------------------------------------------------------
%iconsetup {
    font-family: 'kolagenlife' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'kolagenlife';
    src:    url('../fonts/kolagenlife.eot?20161112');
    src:    url('../fonts/kolagenlife.eot?20161112#iefix') format('embedded-opentype'),
        url('../fonts/kolagenlife.ttf?20161112') format('truetype'),
        url('../fonts/kolagenlife.woff?20161112') format('woff'),
        url('../fonts/kolagenlife.svg?20161112#kolagenlife') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    @extend %iconsetup;
}

.icon-water:before { content: '\61'; } /* 'a' */
.icon-beaker:before { content: '\62'; } /* 'b' */
.icon-eyedropper:before { content: '\63'; } /* 'c' */
.icon-leaf:before { content: '\64'; } /* 'd' */
.icon-droplet:before { content: '\65'; } /* 'e' */
.icon-lemon:before { content: '\66'; } /* 'f' */
.icon-perfume:before { content: '\67'; } /* 'g' */
.icon-fish:before { content: '\68'; } /* 'h' */
.icon-grape:before { content: '\69'; } /* 'i' */
.icon-flask:before { content: '\6a'; } /* 'j' */
.icon-molecular-bond:before { content: '\6b'; } /* 'k' */
.icon-plum:before { content: '\6c'; } /* 'l' */
.icon-aloe-vera:before { content: '\6d'; } /* 'm' */
.icon-left-circle:before { content: '\e800'; } /* '' */
.icon-ok-circle:before { content: '\e801'; } /* '' */
.icon-right-circle:before { content: '\e802'; } /* '' */