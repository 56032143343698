// Style for Main content text

$m: content;

.#{$m} {
    margin: 40px 0;
    position: relative;
    overflow: hidden;

    @include min-screen($md) {
        margin: 80px 0;
    }

    p {
        margin: 30px 30px;
        padding: 0;
        @include font-size(16);
        @include line-height(24);
        font-family: $font2;
        font-weight: $semi_bold;
        color: $color_02;

        strong {
            color: darken($color_02, 15%);
        }

        &:before {
            content: '';
            width: 60px;
            display: inline-block;
        }

        &.quote {
            margin: 0 40px;
            color: $color_03;
            text-align: center;

            background:transparent url('../img/quote@3x.png') left top no-repeat;
            background-size: auto 111px;
            min-height: 111px;
            padding-top: 30px;
            font-weight: $bold;
            font-family: $font2;

            &:before {
                content: none;
            }

            strong {
                color: darken($color_03, 15%);
            }

            @include min-screen($md) {
                margin: 0 160px;
            }
        }

        abbr {
            cursor: help;
        }



        @include min-screen($md) {
            margin: 30px 90px;
            @include font-size(20);
            @include line-height(32);
        }
    }

    ul, ol {
        margin: 0 30px;
        padding: 0;
        list-style-position: inside;

        li {
            @include font-size(16);
            @include line-height(24);
            color: $color_02;
            font-family: $font2;

            strong {
                color: #111;
                font-weight: $bold;
            }
        }

        @include min-screen($md) {
            margin: 0 90px;

            li {
                @include font-size(20);
                @include line-height(28);
            }
        }
    }

    table {
        width: 100%;
        padding: 0;
        margin: 0;

        p {
            margin: 0;

            &:before {
                content: none;
            }
        }
    }


    .bg-graphic {
        position: absolute;
        width: 100%;
        padding-bottom: 100%;
        background-size: 80% auto;
        background-repeat: no-repeat;
        opacity: 0.08;
        @extend %noclick;

        &--left {
            left: 0%;
        }

        &--right {
            right: 0%;
            background-position: top right;
        }

        @include min-screen($md) {
            background-size: 50% auto;
        }
    }
}