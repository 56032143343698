//---------------------------------------------------------------
// CORE SETTINGS
//---------------------------------------------------------------
html {
    font-size: $base-fontSize;
}

body {
    font-family: $font1;
    font-size: 100%;
    line-height: $base-lineHeight;
}

// html, body {
//     width: 100%;
//     height: 100%;
//     overflow:hidden; // to avoid scrollbars
// }

figure {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 0;
}


//---------------------------------------------------------------
// SITE WRAPPERS
//---------------------------------------------------------------
body {
    //padding: 130px 0 0 0;
    min-height: 100vh;
    color: $color_02;
    overflow-x: hidden;
}


.container {
    margin: 0 auto;
    max-width: $container-max-width;

    @include screen($md, $lg - 1) {
        padding: 0 20px;
    }
}


// body > .main-container {
//     min-height: 100vh;
// }

ul {
    @extend %ullist;
}


::selection {
    background-color: rgba(#4f2e2e, 0.5);
}

.text-uppercase {
    text-transform: uppercase;
}