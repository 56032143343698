//**************************************************
// MAIN STYLES
//**************************************************
@import 'site_settings';



//**************************************************
// LIBS & INCLUDES (CORE)
//**************************************************
@import "lib/_normalize";

//@import "includes/_flexbox";
@import "includes/_mixins";
@import "includes/_helpers";
@import "includes/_typography";
@import "includes/_layout";
@import "includes/_iconset";
// @import "includes/_utility";
// @import "modules/_styleguide";

@import "includes/_headings";
@import "includes/_buttons";
@import "includes/_inputs";
@import "includes/_animations";

@import "includes/_print";



//**************************************************
// MODULES
//**************************************************
//shared UI modules
// @import "modules/_cards";
// @import "modules/_buttons";
// @import "modules/_modal";
// @import "modules/_wysiwyg";
// @import "modules/_forms";
@import "modules/_header";
@import "modules/_navigation";
@import "modules/_content";
@import "modules/_footer";



//specific modules
// @import "modules/_header";





//IE upgrade message
.browserupgrade {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	margin: 0;
	padding: 10px;
	color: #FFF;
	background: #e50278;
	text-align: center;
	z-index: 99999;
	font-size: 1.6em;

	a {
		color: #FFF;
		font-weight: $bold;
		text-decoration: underline;
	}
}